import { Button, Col, Table } from 'antd';
import ModalComponent from 'Components/Modal';
import styled from 'styled-components';
import { COLORS_SUBJECT } from 'Utils/data-default';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.white};
	height: ${({ height }) => height};
	border-radius: 1.75rem;
	margin: 1rem 0 4rem 0;

	.table-head {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1rem;
		.table-head-left {
			.content {
				display: flex;
				align-items: center;
				svg.svg-icon--material {
					stroke: #4e68f9;
				}
				.title {
					font-size: 1rem;
				}
				.record-counting {
					font-weight: 500;
					margin-left: 0.5rem;
					font-size: 0.6rem;
					opacity: 0.5;
				}
			}
		}
		.table-head-right {
			.create {
				color: #46bcaa;
				background-color: #edf8f7;
				border: 1px solid #edf8f7;
				font-weight: 500;
				font-size: 1rem;
				border-radius: 0.75rem;
				transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
					border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
				&:hover {
					color: #fff;
					background-color: #46bcaa;
					border-color: #46bcaa;
				}
			}
			.order {
				color: #4d69fa;
				background-color: #edf0ff;
				border: 1px solid #edf0ff;
				font-weight: 500;
				font-size: 1rem;
				border-radius: 0.75rem;
				transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
					border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
				&:hover {
					color: #fff;
					background-color: #4d69fa;
					border-color: #4d69fa;
				}
			}
			.more {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #e7eef8;
				border-color: #e7eef8;
				border-radius: 0.75rem;
				&:hover {
					color: #1f2128;
					background-color: #ebf1f9;
					border-color: #e9f0f9;
				}
				svg {
					width: 15px;
					height: 15px;
				}
			}
		}
	}

	.table-foot {
		display: flex;
		justify-content: space-between;
		margin-top: 1rem;
		.table-foot-left {
			.info {
				font-size: 0.75rem;
				font-weight: 300;
			}
		}
		.table-foot-right {
			.ant-pagination {
				.ant-pagination-item {
					margin-right: 0;
					background-color: #e9ecef;
					border-color: #e9ecef;
					border-radius: 0;
					font-size: 0.75rem;
					& > a:hover {
						color: #564aa9;
						background-color: #e5e9ed;
					}
				}
				.ant-pagination-prev {
					margin-right: 0;
					background-color: #e9ecef;
					border-color: #e9ecef;
					border-top-left-radius: 1rem;
					border-top-right-radius: 0;
					border-bottom-left-radius: 1rem;
					border-bottom-right-radius: 0;
					&:hover {
						background-color: #e9ecef;
					}
					button {
						justify-content: center;
						align-items: center;
						background-color: transparent;
						border: none;
						border-radius: 0;
						& svg {
							width: 8px;
							height: 8px;
						}
						&:not(:disabled):hover {
							svg {
								fill: #564aa9;
							}
						}
					}
				}
				.ant-pagination-next {
					margin-right: 0;
					background-color: #e9ecef;
					border-color: #e9ecef;
					border-top-left-radius: 0;
					border-top-right-radius: 1rem;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 1rem;
					&:hover {
						background-color: #e9ecef;
					}
					button {
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: transparent;
						border: none;
						border-radius: 0;
						& svg {
							width: 8px;
							height: 8px;
						}
						&:not(:disabled):hover {
							svg {
								fill: #564aa9;
							}
						}
					}
				}
				.ant-pagination-item-active {
					background-color: #6c5dd3;
					border-color: #6c5dd3;
					z-index: 3;
					& > a {
						color: #fff;
						&:hover {
							background-color: #6c5dd3;
							border-color: #6c5dd3;
							color: #fff;
						}
					}
				}
			}
		}
	}

  .no_data {
    .ant-table-placeholder {
      .ant-table-cell {
        height: 80px;
      }
    }
  }
  .course_name{
    white-space: nowrap; 
    width: 100%; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }
  .on-off-table{
    max-width: 39px;
  }
  .cell__course_name{
    max-width: 200px !important;
    min-width: 200px !important;
  }
  .total_NumberOfPeriods{
    vertical-align: middle;
  }
  .title_for_checking{
    font-size: 12px !important;
  }
  .sum__time{
    font-size: 12px;
  }
  .title-sort{
    display: flex;
    align-items: center;
  }
  .icon__hide-data{
    vertical-align: middle;
    svg{
      height: 25px;
      width: 25px;
    }
  }
  .table__not-full {
    table{
      .ant-table-thead {
        tr:first-child{
          .ant-table-cell{
            padding: 0 !important;
          }
        }
      }
    }
  }
  .active  {
		cursor: grabbing;
	}
`;

export const TableStyle = styled(Table)`
	height: 100%;
    /* thead{
      animation: all 1s step-end;
    } */
    
    /* @keyframes slide-in {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  } */

  /* .table { */
    /* animation: slide-in 1s ease-in-out; */
  /* } */
	.ant-pagination {
		display: flex;
		justify-content: center;
	}
	display: block;
	overflow-x: auto;
	white-space: nowrap;
  
  .ant-table-container {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-table-body{
    overflow: auto auto !important;
    .bg-english{
      background-color: ${COLORS_SUBJECT.english};
    }
    .bg-national_language{
      background-color: ${COLORS_SUBJECT.national_language};
    }
    .bg-society{
      background-color: ${COLORS_SUBJECT.society};
    }
    .bg-math{
      background-color: ${COLORS_SUBJECT.math};
    }
    .bg-science{
      background-color: ${COLORS_SUBJECT.science};
    }
    .bg-eiken_specialized_course{
      background-color: ${COLORS_SUBJECT.eiken_specialized_course};
    }
    .border-english {
      td {
        border-top: 1px solid ${COLORS_SUBJECT.english};
        border-bottom: 1px solid ${COLORS_SUBJECT.english};
        &:first-child{
          border-left: 1px solid ${COLORS_SUBJECT.english};
        }
        &:last-child{
          border-right: 1px solid ${COLORS_SUBJECT.english};
        }
      }
    }
    .border-national_language {
      td {
        border-top: 1px solid ${COLORS_SUBJECT.national_language};
        border-bottom: 1px solid ${COLORS_SUBJECT.national_language};
        &:first-child{
          border-left: 1px solid ${COLORS_SUBJECT.national_language};
        }
        &:last-child{
          border-right: 1px solid ${COLORS_SUBJECT.national_language};
        }
      }
    }
    .border-society {
      td {
        border-top: 1px solid ${COLORS_SUBJECT.society};
        border-bottom: 1px solid ${COLORS_SUBJECT.society};
        &:first-child{
          border-left: 1px solid ${COLORS_SUBJECT.society};
        }
        &:last-child{
          border-right: 1px solid ${COLORS_SUBJECT.society};
        }
      }
    }
    .border-math {
      td {
        border-top: 1px solid ${COLORS_SUBJECT.math};
        border-bottom: 1px solid ${COLORS_SUBJECT.math};
        &:first-child{
          border-left: 1px solid ${COLORS_SUBJECT.math};
        }
        &:last-child{
          border-right: 1px solid ${COLORS_SUBJECT.math}
        }
      }
    }
    .border-science {
      td {
        border-top: 1px solid ${COLORS_SUBJECT.science};
        border-bottom: 1px solid ${COLORS_SUBJECT.science};
        &:first-child{
          border-left: 1px solid ${COLORS_SUBJECT.science};
        }
        &:last-child{
          border-right: 1px solid ${COLORS_SUBJECT.science};
        }
      }
    }
    .border-eiken_specialized_course {
      td {
        border-top: 1px solid ${COLORS_SUBJECT.eiken_specialized_course};
        border-bottom: 1px solid ${COLORS_SUBJECT.eiken_specialized_course};
        &:first-child{
          border-left: 1px solid ${COLORS_SUBJECT.eiken_specialized_course};
        }
        &:last-child{
          border-right: 1px solid ${COLORS_SUBJECT.eiken_specialized_course};
        }
      }
    }
  }
	@media only screen and (max-height: 600px) {
		/* .ant-table {
			display: block;
			overflow-x: auto;
			white-space: nowrap;
		} */
		.ant-table-container {
    	border-inline-end: 1px solid #f0f0f0;
    }
	}

  .ant-table-thead{
	
    tr:first-child{
      .ant-table-cell:first-child {
        border-right: 0 !important; 
      }

      .ant-table-cell {
        border-inline-end: 1px solid #f0f0f0;
      }

      .ant-table-selection-column {
        padding: 0;

        .ant-table-selection {
          position: absolute;
          width: 100%;
          top: 66px;
          display: block;
        }
      }

      .ant-table-cell:nth-child(3),
      .ant-table-cell:nth-child(4),
      .ant-table-cell:nth-child(5),
      .ant-table-cell:nth-child(6),
      .ant-table-cell:nth-child(7),
      .ant-table-cell:nth-child(8),
      .ant-table-cell:nth-child(9),
      .ant-table-cell:nth-child(10),
      .ant-table-cell:nth-child(11),
      .ant-table-cell:nth-child(12),
      .ant-table-cell:nth-child(13),
      .ant-table-cell:nth-child(14),
      .ant-table-cell:nth-child(15),
      .ant-table-cell:nth-child(16),
      .ant-table-cell:nth-child(17) {
        padding: 0;
      }
			/* .ant-table-cell:nth-child(1){
				background: linear-gradient(to bottom, #00003A 0%,#00003A 29.1%,#002C68 24.1%,#002C68 58.5%,#fafafa 49%,#fafafa 50%, #fafafa 100%);

				@media only screen and (max-width: 2100px) and (min-width: 2000px) {
				  background: linear-gradient(to bottom, #00003A 0%,#00003A 29.1%,#002C68 24.1%,#002C68 58.5%,#fafafa 49%,#fafafa 50%, #fafafa 100%);
				}

			} */

      /* .scheduled_course_1,
	    .scheduled_course_2  */
      .scheduled_course_1,
	    .scheduled_course_2 {
        background: linear-gradient(to bottom, #00003A 50%, #002C68 50%) !important;
        color: #FFFFFF !important;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        vertical-align: middle;
        padding: 0;
      }
      .ant-table-cell:nth-child(1) {
        border-right: 0px !important;
      }
    }

    tr:nth-child(2) {
      .ant-table-cell {
        padding: 12px;
      } 
	  .ant-table-cell:nth-child(5) {
		padding: 6px 14px;
	  }
    }

    td.ant-table-selection-column {
      min-width: 50px;
      max-width: 50px;
    }

    tr:nth-child(2) {
      .ant-table-cell {
        padding: 2px;
      }
    }
  }

  .ant-table-tbody{
    font-weight: 500;
    font-size: 12px;
    line-height: 39px;
    color: #000000;

    [role=button] {
      cursor: auto;
    } 

		.ant-table-cell {
			padding: 0px;
		}
    .ant-table-cell:nth-child(1) {
      max-width: 45px;

      span {
        min-width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    /* .ant-table-cell:nth-child(10), */
    .ant-table-cell:nth-child(11),
    .ant-table-cell:nth-child(12),
    .ant-table-cell:nth-child(13),
    .ant-table-cell:nth-child(14),
    .ant-table-cell:nth-child(15),
    .ant-table-cell:nth-child(16),
    .ant-table-cell:nth-child(17),
    .ant-table-cell:nth-child(18),
    .ant-table-cell:nth-child(19),
    .ant-table-cell:nth-child(20),
    .ant-table-cell:nth-child(21),
    .ant-table-cell:nth-child(22),
    .ant-table-cell:nth-child(23),
    .ant-table-cell:nth-child(24),
    .ant-table-cell:nth-child(25),
    .ant-table-cell:nth-child(26),
    .ant-table-cell:nth-child(27),
    .ant-table-cell:nth-child(28),
    .ant-table-cell:nth-child(29),
    .ant-table-cell:nth-child(30),
    .ant-table-cell:nth-child(31),
    .ant-table-cell:nth-child(32),
    .ant-table-cell:nth-child(33),
    .ant-table-cell:nth-child(34),
    .ant-table-cell:nth-child(35),
    .ant-table-cell:nth-child(36),
    .ant-table-cell:nth-child(37),
    .ant-table-cell:nth-child(38),
    .ant-table-cell:nth-child(39),
    .ant-table-cell:nth-child(40),
    .ant-table-cell:nth-child(41),
    .ant-table-cell:nth-child(42),
    .ant-table-cell:nth-child(43),
    .ant-table-cell:nth-child(44),
    .ant-table-cell:nth-child(45),
    .ant-table-cell:nth-child(46),
    .ant-table-cell:nth-child(47),
    .ant-table-cell:nth-child(48),
    .ant-table-cell:nth-child(49),
    .ant-table-cell:nth-child(50),
    .ant-table-cell:nth-child(51),
    .ant-table-cell:nth-child(52),
    .ant-table-cell:nth-child(53),
    .ant-table-cell:nth-child(54),
    .ant-table-cell:nth-child(55),
    .ant-table-cell:nth-child(56),
    .ant-table-cell:nth-child(57),
    .ant-table-cell:nth-child(58),
    .ant-table-cell:nth-child(59),
    .ant-table-cell:nth-child(60) {
      padding: 6px 0px;
    }

    .list_schedule {
      background-color: #ccc;
    }

    .show_delete {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    tr {
      .ant-table-cell:nth-child(5) {
        max-width: 400px;
      }
    }

    @media only screen and (max-width: 1024px) {
      tr {
        .ant-table-cell:nth-child(5) {
          max-width: 320px;
        }
      }
    } 

    @media only screen and (max-width: 768px ) {
      tr {
        .ant-table-cell:nth-child(5) {
          max-width: 250px;
        }
      }
    }
    
  }

  

	.ant-table {
		border-radius: 0.75rem;
		max-height: 90%;

		th{
			color: #6D737A !important;
			font-weight: 500;
			font-size: 1.3rem;
			line-height: 30px;
      white-space: nowrap;
		}

		.ant-table-cell::before{
				background-color: transparent !important;
				content: "" !important;
			}

		.ant-table-content {
			border-radius: 0.75rem;
		}
	}

	.supplementary {
		background: #00bfff;
		padding: 10px;
		line-height: 30px;
    /* white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; */
	}

	.not_supplement {
		padding: 10px;
		line-height: 30px;
    /* white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; */
	}

  .calender_time {
    height: 38px;
    background-color: #ccc;
    width: 100%;
	cursor: pointer;
  }
  .cursor-pointer{
    cursor: pointer;
    &:hover{
      color: #3f8ff5;
    }
  }

`;

export const SuccessBtn = styled(Button)`
  background: #F0A7AB;
  font-style: normal;
  font-weight: 600;
	font-size: 12px;
	line-height: 39px;
  color: #7D0006;
  font-family: 'Public Sans';
  height: 22px;
  min-width: 43px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #F0A7AB;
	position: absolute;
	top: 14px;
  margin-left: 4px;
	/* position: absolute;
	top: 25px;
	right: -30px;
	z-index: 100; */
  &:hover{
    color: #7D0006 !important;
    border-color: #F0A7AB !important;
    opacity: 0.7;
  }
`
export const FromBtn = styled(Button)`
  background: ${props => props.isActive ? "#7d0006" : "#f1dca3"};
  font-style: normal;
  font-weight: 600;
	font-size: 12px;
	line-height: 39px;
  color: ${props => props.isActive ? "#FFFFFF" : "#5a4309"};
  font-family: 'Public Sans';
  height: 22px;
  min-width: 43px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid ${props => props.isActive ? "#7d0006" : "#f1dca3"};

  &:hover{
    color: ${props => props.isActive ? "#FFFFFF" : "#5a4309"} !important;
    border-color:${props => props.isActive ? "#7d0006" : "#f1dca3"} !important;
    opacity: 0.7;
  }
`
export const ToBtn = styled(Button)`
  background: ${props => props.isActive ? "#a4d0f5" : "#dbdbdb"};
  font-style: normal;
  font-weight: 600;
	font-size: 12px;
	line-height: 39px;
  color: #1b4060;
  font-family: 'Public Sans';
  height: 22px;
  min-width: 43px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #a4d0f5;

  &:hover{
    color: #1b4060 !important ;
    border-color: ${props => props.isActive ? "#a4d0f5" : "#dbdbdb"} !important ;
    opacity: 0.7;
  }

	&:disabled{
		background: ${props => props.isActive ? "#a4d0f5" : "#dbdbdb"};
		color: #1b4060 ;
	}
`

export const ColWraper= styled(Col)`
  display: flex;
  justify-content: flex-end;
`

export const EndCol= styled(Col)`
  display: flex;
  justify-content: flex-end;
`

export const StartCol= styled(Col)`
  display: flex;
  justify-content: flex-start;
`
export const NormalButton = styled.button`
  background: #E7E3E8;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #000000;
  font-family: 'Public Sans';
  height: 45px;
  min-width: 13rem;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #E7E3E8;
`

export const FailureBtn = styled.button`
  background: #7D0006;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #FFFFFF;
  font-family: 'Public Sans';
  height: 45px;
  min-width: 13rem;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #7D0006;
`

export const ModalComponentWrapper= styled(ModalComponent)`
  
`

export const MoveButtonGroup= styled.div`
	position: absolute;
	top: 0px;
	right: 90px;
	z-index: 100;
	height: 100%;
	display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const MoveUp= styled.div`
	visibility: ${props => props.visible ? "visible" : "hidden"} !important;
  cursor: pointer;
	width: 15px;
	height: 15px;
	background: #CDE2FF;
	border-radius: 4px;
	display: flex;
    align-items: center;
    justify-content: center;
	position: absolute;
    top: -4px;
    right: 138px;;
`

export const MoveDown= styled.div`
	visibility: ${props => props.visible ? "visible" : "hidden"}  !important;
  cursor: pointer;
	width: 15px;
	height: 15px;
	background: #CDE2FF;
	border-radius: 4px;
	display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -4px;
  right: 138px;
  z-index: 1;
`